import { h, cloneElement } from 'preact'
import { useRef, useCallback } from 'preact/hooks'
import PropTypes from 'prop-types'

import history from 'lib/history'
import isMobile from 'lib/isMobile'
import useToggle from 'lib/useToggleHook'
import classNames from 'lib/classNames'
import { focusWithin } from 'lib/DOMHelpers'

import Button from 'components/Button'
import ContextMenu from 'components/ContextMenu'
import './index.sass'

export default function DropdownMenu({
  className,
  children,
  disabled,
  rightAligned,
  options,
  ...props
}){
  const rootRef = useRef()
  const buttonRef = useRef()
  const menuRef = useRef()
  const [isOpen, _open, close] = useToggle(false)
  const open = useCallback(() => {
    _open()
    setTimeout(
      () => {
        if (menuRef.current)
          focusWithin(menuRef.current.base || menuRef.current)
      },
      50
    )
  }, [])

  const onKeyDown = useCallback(event => {
    if (event.key === 'ArrowDown'){ //} || event.key === 'Enter') {
      event.preventDefault()
      open()
    }
  }, [])

  const button = cloneElement(children, {
    ref: buttonRef,
    disabled,
    onKeyDown,
    onMouseDown: open,
    onClick: open,
  })

  return <span
    {...props}
    ref={rootRef}
    className={classNames('DropdownMenu', {className})}
  >
    {button}
    {isMobile
      ? <Select {...{disabled, options}}/>
      : <Menu {...{
        menuRef,
        isOpen,
        close,
        buttonRef,
        rightAligned,
        options,
      }}/>
    }
  </span>
}

DropdownMenu.propTypes = {
  className: PropTypes.string,
  children: PropTypes.node,
  disabled: PropTypes.bool,
  rightAligned: PropTypes.bool,
  options: PropTypes.arrayOf(
    PropTypes.oneOfType([
      PropTypes.oneOf(['--']),
      PropTypes.shape({
        disabled: PropTypes.bool,
        value: PropTypes.string.isRequired,
        onSelect: PropTypes.func,
        href: PropTypes.string,
      })
    ])
  ).isRequired,
  onKeyDown: PropTypes.func,
}

function Menu({
  menuRef,
  isOpen,
  close,
  buttonRef,
  rightAligned,
  options,
}){

  const onKeyDown = useCallback(event => {
    const moveFocus = delta => {
      const menuNode = menuRef.current.base.firstElementChild
      const menuItems = [...menuNode.querySelectorAll('.DropdownMenu-Option')]
      if (menuItems.length === 0) return
      const currentIndex = menuItems.findIndex(item =>
        global.document.activeElement === item
      )
      let nextIndex = currentIndex === -1
        ? 0
        : (currentIndex + delta) % menuItems.length
      if (nextIndex === -1) nextIndex = menuItems.length - 1
      const nextItem = menuItems[nextIndex]
      if (nextItem) {
        event.preventDefault()
        nextItem.focus()
      }
    }

    if (!event.metaKey){
      if (event.key === 'Escape'){
        close()
        setTimeout(
          () => { buttonRef.current.base.focus() },
          50
        )
      }
      if (event.key === 'ArrowDown') moveFocus(1)
      if (event.key === 'ArrowUp') moveFocus(-1)
    }
  }, [])

  return <ContextMenu {...{
    menuRef,
    open: isOpen,
    onClose: close,
    anchorRef: buttonRef,
    rightAligned,
    onKeyDown,
  }}>
    {options.map(option => {
      if (option === '--') return <ContextMenu.Hr/>
      let { disabled, value, onSelect, href } = option
      const select = () => {
        close()
        if (typeof onSelect === 'function') onSelect()
      }
      return <Button {...{
        className: 'DropdownMenu-Option',
        type: 'none',
        disabled,
        value,
        href,
        onMouseUp: select,
        onMouseOver: event => { event.target.focus() },
        onKeyDown: event => {
          if (event.key === 'Enter') select()
        }
      }}/>
    })}
  </ContextMenu>
}

function Select({ disabled, options }){
  const onChange = event => {
    event.preventDefault()
    const option = options[event.target.value]
    if (option){
      if (typeof option.onSelect === 'function') option.onSelect()
      else if (option.href) history.visit(option.href)
    }
    event.target.value = -1
    return false
  }
  return <select {...{disabled, onChange}}>
    <option value={-1} selected disabled/>
    {options.map((option, index) => {
      if (option === '--') return <option disabled>-----------</option>
      let { disabled, value, onSelect } = option
      return <option {...{disabled, value: index, label: value, onSelect, }}>{value}</option>
    })}
  </select>
}
