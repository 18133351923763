import { h } from 'preact'
import PropTypes from 'prop-types'

import classNames from 'lib/classNames'
import { useMyPublicProfileDid } from 'resources/auth'
import { useMyOrganizationMembership } from 'lib/membershipAppStateHooks'

import IconButton from 'components/IconButton'

export default function ChatButton({
  className,
  organizationApikey,
  publicProfileDid,
  ...props
}){
  const myPublicProfileDid = useMyPublicProfileDid()
  const { myOrganizationMembership } =
    useMyOrganizationMembership(organizationApikey, 'ChatButton')

  if (
    !myOrganizationMembership ||
    !myOrganizationMembership.acceptedAt ||
    publicProfileDid === myPublicProfileDid
  ) return

  return <IconButton {...{
    ...props,
    className: classNames('ChatButton', { className }),
    type: 'messages',
    href: `/${organizationApikey}/chat/${publicProfileDid}`,
  }} />
}

ChatButton.propTypes = {
  className: PropTypes.string,
  organizationApikey: PropTypes.string.isRequired,
  publicProfileDid: PropTypes.string.isRequired,
}
