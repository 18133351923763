import { h } from 'preact'
import PropTypes from 'prop-types'

import classNames from 'lib/classNames'
import EndUserAvatar from 'components/EndUserAvatar'
import './index.sass'

export default function EndUserAvatarStack({
  className,
  publicProfiles,
  size,
  bordered,
  ...props
}){
  const length = publicProfiles.length
  if (length === 0) return
  const children = publicProfiles.map((publicProfile, index) =>
    <EndUserAvatar {...{
      publicProfile,
      key: index,
      size,
      bordered,
    }}/>
  )
  if (length === 1) return children[0]
  const two = length === 2
  const three = length === 3
  const more = length > 3
  if (more) children.length = 3
  return <div
    {...props}
    className={classNames('EndUserAvatarStack', {
      className, [size]: 1, two, three, more
    })}
  >
    {children}
    {more && <div className="EndUserAvatarStack-length">{length}</div>}
  </div>
}

EndUserAvatarStack.propTypes = {
  className: PropTypes.string,
  publicProfiles: PropTypes.arrayOf(
    PropTypes.shape({
      avatar: EndUserAvatar.propTypes.src,
    })
  ),
  size: EndUserAvatar.propTypes.size,
  bordered: EndUserAvatar.propTypes.bordered,
}
