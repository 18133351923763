import { h } from 'preact'

import Form from 'components/Form'
import CountryDropdownPicker from 'components/CountryDropdownPicker'
import AvatarInput from 'components/AvatarInput'
import Link from 'components/Link'
import CropImageInputFormRow from 'components/CropImageInputFormRow'
import './index.sass'

export default function PublicProfileFormFields({ form, editForm, submitText, onUploading }) {
  const username = (
    form.value.username ||
    (form.persistedValues && form.persistedValues.username) ||
    ''
  )
  const profileUrl = `${window.location.origin}/@${username}`
  return <div className="PublicProfileFormFields">
    <CropImageInputFormRow
      cropImageInput={
        form.bindInput({
          valueProp: 'avatar',
          input: <AvatarInput {...{ onUploading }}/>
        })
      }
    >
      {form.textItem({
        valueProp: 'displayName',
        label: 'DISPLAY NAME',
        name: 'name',
        autocomplete: 'name',
        maxlength: 35,
      })}
      {editForm ||
        form.textItem({
          valueProp: 'username',
          label: 'USERNAME',
          name: 'username',
          autocomplete: 'username',
          maxlength: 35,
          pattern: '[A-Za-z0-9_-]+',
          required: true,
        })
      }
      <Form.Item>
        <Form.Label>PERSONAL URL</Form.Label>
        <Link
          className="PublicProfileFormFields-personalUrl"
          type="link"
          href={profileUrl}
          disabled={!editForm}
        >{profileUrl}</Link>
      </Form.Item>
    </CropImageInputFormRow>
    { editForm &&
      <Form.Row>
        {form.textItem({
          valueProp: 'city',
          label: 'CITY',
          name: 'city',
          autocomplete: 'address-level1 locality city',
        })}
        {form.textItem({
          valueProp: 'province',
          label: 'STATE / PROVINCE',
          name: 'province',
          autocomplete: 'administrative-area state province region',
        })}
      </Form.Row>
    }
    <Form.Row>
      {form.item({
        valueProp: 'country',
        label: 'COUNTRY',
        input: <CountryDropdownPicker />
      })}
      {form.textItem({
        valueProp: 'postalCode',
        label: isDataYogi ? 'POSTCODE / ZIPCODE' : 'ZIP CODE',
        name: 'postal-code',
        autocomplete: 'postal-code',
      })}
    </Form.Row>
    { editForm && !isDataYogi &&
      <Form.Row>
        {form.textAreaItem({
          valueProp: 'bio',
          name: 'bio',
          label: 'BIO',
        })}
      </Form.Row>
    }
    <Form.ButtonRow className="PublicProfileFormFields-buttonRow">
      {editForm && form.resetButton({})}
      {form.submitButton({
        value: `${submitText.submit}`,
        submittingValue: `${submitText.submitting}`,
      })}
    </Form.ButtonRow>
  </div>
}
