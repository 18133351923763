import { h, Fragment } from 'preact'
import { useState } from 'preact/hooks'
import PropTypes from 'prop-types'

import {
  useUpdateOrganizationMembership,
  useMyPublicProfile,
} from 'lib/membershipAppStateHooks'

import Form from 'components/Form'
import FormBuilder from 'components/FormBuilder'
import SwitchListMember from 'components/SwitchListMember'

export default function OrganizationMembershipEditForm({
  className = '',
  organizationMembership,
  publicProfile,
  ...props
}){
  const [value, onChange] = useState({})
  const { myPublicProfile } = useMyPublicProfile('OrganizationMembershipEditForm')

  const {
    updateOrganizationMembership,
    updatingOrganizationMembership,
    errorUpdatingOrganizationMembership,
  } = useUpdateOrganizationMembership(
    organizationMembership,
    'OrganizationMembershipEditForm',
  )

  return <FormBuilder {...{
    ...props,
    className: `OrganizationMembershipEditForm ${className}`,
    persistedValues: organizationMembership,
    value,
    onChange,
    onSubmit: changes => {
      if (changes.admin === false) {
        const target = publicProfile.did === myPublicProfile.did
          ? 'yourself'
          : 'this user'
        const confirmation = `Are you sure you want to take away admin privileges from ${target}?`
        if (!confirm(confirmation)) return // eslint-disable-line
      }
      updateOrganizationMembership(changes)
    },
    submitting: updatingOrganizationMembership,
    error: errorUpdatingOrganizationMembership,
    render: form =>
      <Fragment>
        <Form.Row>
          {form.item({
            valueProp: 'curator',
            input: <SwitchListMember
              label="curator"
              description="Manages feed, forum and memberships"
            />
          })}
        </Form.Row>
        <Form.Row>
          {form.item({
            valueProp: 'admin',
            input: <SwitchListMember
              label="admin"
              description="Full administrative privileges"
            />
          })}
        </Form.Row>
        {form.buttonRow('Update', 'Updating…')}
      </Fragment>
    ,
  }}/>
}

OrganizationMembershipEditForm.propTypes = {
  className: PropTypes.string,
  organizationMembership: PropTypes.shape({
    admin: PropTypes.bool,
    curator: PropTypes.bool,
  }).isRequired,
  publicProfile: PropTypes.object,
}
