import api from '../api'

import { loadEntity } from 'lib/actionHelpers'

export default async function loadPublicOrganizations(){
  return loadEntity.call(this, {
    reload: false,
    entityKey: 'publicOrganizationApikeys',
    request: () =>
      api.getPublicOrganizations().then(({ organizations, memberships, publicProfiles }) => {
        this.takeAction('organization.addOrganizationsToAppState', organizations)
        this.takeAction('publicProfiles.addPublicProfilesToAppState', publicProfiles)
        this.takeAction('organizationMemberships.addMembershipsToAppState', memberships)
        return new Set(organizations.map(o => o.apikey))
      })
    ,
  })
}
