import { h } from 'preact'

import { setPageTitle } from 'lib/Page'
import { useChatSynopsis, useFasterChatPolling } from 'lib/chatHooks'

import SingleColumn from 'components/SingleColumn'
import ContentBox from 'components/ContentBox'
import ChatNotificationsList from 'components/ChatNotificationsList'

export default function ChatPage(){
  setPageTitle('Account Settings')

  const { chatSynopsis: synopsis } = useChatSynopsis('ChatPage')
  useFasterChatPolling('ChatPage')

  return <SingleColumn>
    <ContentBox>
      <ChatNotificationsList {...{synopsis}}/>
    </ContentBox>
  </SingleColumn>
}
