import { h } from 'preact'
import PropTypes from 'prop-types'

import classNames from 'lib/classNames'

import OrganizationMembersList from 'components/OrganizationMembersList'

export default function OrganizationAdminsList({
  className,
  organization,
  isMember,
  ...props
}){
  return <OrganizationMembersList {...{
    ...props,
    className: classNames('OrganizationAdminsList', { className }),
    header: 'Curators',
    collapsable: true,
    organization,
    admins: true,
    pathPrefix: isMember ? undefined : ``,
  }}/>
}

OrganizationAdminsList.propTypes = {
  className: PropTypes.string,
  organization: OrganizationAdminsList.organization,
  isMember: PropTypes.bool.isRequired,
}
