import { h } from 'preact'
import PropTypes from 'prop-types'

import Header from 'components/Header'
import IconRowList from 'components/IconRowList'
import DisplayNameAndAvatarRow from 'components/DisplayNameAndAvatarRow'

export default function PeopleList({
  className = '',
  people,
  filter,
  onEmpty,
  ...props
}){
  const empty = !people || people.length === 0

  if (filter) {
    filter = filter.toLowerCase()
    people = people.filter(person =>
      person.username.toLowerCase().includes(filter)
    )
  }

  return <IconRowList
    className={`PeopleList ${className}`}
    {...props}
  >
    {empty
      ? <Header size="sm" centered italic>{onEmpty}</Header>
      : people.map(person =>
        <DisplayNameAndAvatarRow {...{key: person.username, ...person}}/>
      )
    }
  </IconRowList>
}

PeopleList.propTypes = {
  className: PropTypes.string,
  people: PropTypes.arrayOf(
    PropTypes.shape({
      username: PropTypes.string.isRequired,
      displayName: PropTypes.string.isRequired,
      avatar: PropTypes.string.isRequired,
      active: PropTypes.bool,
      href: PropTypes.string,
      suffix: PropTypes.node,
      buttons: PropTypes.node,
    })
  ).isRequired,
  filter: PropTypes.string,
  onEmpty: PropTypes.node,
}
